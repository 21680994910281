<template>
  <a class="button mo:button--full" :class="{'button--full': !small}" @click.prevent="select">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      default: {
        title: '',
        imageSrc: '',
        imageSrcSet: '',
        price: 0,
        id: null
      }
    },
    preselect: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.preselect) {
      this.$store.commit('selectVehicle', this.vehicle)
    }
  },
  methods: {
    select() {
      this.$store.commit('selectVehicle', this.vehicle)
      const $a = document.querySelector('a[name="inquiry"]')
      window.scrollTo({ top: $a.offsetTop, behavior: 'smooth' })
    }
  }
}
</script>
