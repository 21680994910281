<template>
  <div class="swiper-container  v-cloak">
    <slot name="before-wrapper" />
    <div class="swiper-wrapper">
        <slot />
    </div>
    <div
      v-if="defaultPagination"
      class="swiper-pagination" />
    <div
      v-if="defaultScrollbars"
      class="swiper-scrollbar" />
    <div
      v-if="defaultNavigation"
      class="swiper-button-next" />
    <div
      v-if="defaultNavigation"
      class="swiper-button-prev" />
    <slot name="after-wrapper" />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { Navigation, Autoplay, Pagination, Swiper } from 'swiper/dist/js/swiper.esm.js'

Swiper.use([Pagination, Autoplay, Navigation])

export default {
  name: 'SwiperSlider',
  props: {
    options: { type: Object, required: true },
  },

  computed: {
    defaultPagination() {
      return this.options.pagination && this.options.pagination.el == '.swiper-pagination'
    },
    defaultScrollbars() {
      return this.options.scrollbar && !this.options.scrollbar.el
    },
    defaultNavigation() {
      const { navigation: nav } = this.options
      return nav && (nav.nextEl == '.swiper-button-next' && nav.prevEl == '.swiper-button-prev')
    },
  },

  watch: {
    options() {
      this.recreateSwiper()
    },
  },

  mounted() {
    this.recreateSwiper()
  },

  updated() {
    this.swiper.update()
  },

  beforeDestroy() {
    if (this.swiper) this.swiper.destroy()
  },

  methods: {
    control(swiper) {
      this.controlled = swiper
      this.recreateSwiper()
    },

    recreateSwiper() {
      if (this.swiper) this.swiper.destroy()
      const o = Object.assign({}, {
        preloadImages: false,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: true,
        navigation: {}
      },
      this.options,
      {
        controller: {
          control: this.controlled,
        },
      })

      this.swiper = new Swiper(this.$el, o)
      this.$emit('update:swiper', this.swiper)
    },
  },
}
</script>

<style>
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 32px;
  height: 2px;

  & .swiper-pagination-bullet {
    background: #bbb;
    opacity: 0.4;
    width: 80px;
    height: 2px;
    margin: 0 10px;
    border-radius: 0;
  }

  & .swiper-pagination-bullet-active {
    opacity: 0.8;
    background: white
  }
}

.swiper-slide {
  box-sizing: border-box;
}

.swiper-button-next.swiper-button-next,
.swiper-button-prev.swiper-button-prev {
  background-image: url(~@/assets/svg/icons/arrow-h-w.svg);
  background-color: rgba(0, 0, 0, 0.2);
  background-size: 24%;
  background-position: 55% 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid white;
}

.stage .swiper-button-next.swiper-button-next,
.stage .swiper-button-prev.swiper-button-prev {
  top: 40vw;
}

.swiper-container.simple {
  & .swiper-button-next,
  & .swiper-button-prev {
    background-image: url(~@/assets/svg/icons/arrow-h.svg);
    background-color: transparent;
  }
}

.swiper-button-next.swiper-button-next {
  right: 0.5rem
}

.swiper-button-prev.swiper-button-prev {
  transform: scaleX(-1);
  left: 0.5rem
}

@screen md {
  .stage .swiper-button-next.swiper-button-next,
  .stage .swiper-button-prev.swiper-button-prev {
    top: 50%;
  }
}

@screen mo {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 53vw;

    & .swiper-pagination-bullet {
      width: 48px;
      height: 1px;
      margin: 0 4px;
    }
  }
}
</style>
